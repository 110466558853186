<template>
  <div class="supplier bj">
    <div class="title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      ></el-button>
      <div class="seach">
        <div class="inputs">
          <el-input
            v-model="from1.order_no"
            placeholder="请输入订单号"
            clearable
          ></el-input>
        </div>
        <div style="margin-right: 10px">
          <el-date-picker
            v-model="from1.as_at"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择创建日期"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.mobile"
            placeholder="请输入客户电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.as_no"
            placeholder="请输入售后单号"
            clearable
          ></el-input>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button @click="exportHandle" type="warning" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="as_no" align="center" label="售后单号">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.as_no) }}
          </template>
        </el-table-column>
        <el-table-column prop="as_at" align="center" label="创建日期时间">
          <template slot-scope="scope">
            {{ $empty.time(scope.row.as_at) }}
          </template>
        </el-table-column>
        <el-table-column prop="order_no" align="center" label="订单号">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.order_no) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="客户姓名">
          <template
            slot-scope="scope"
            v-if="scope.row.order && scope.row.order.member"
          >
            {{ $empty.empty(scope.row.order.member.fullname) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="客户电话">
          <template
            slot-scope="scope"
            v-if="scope.row.order && scope.row.order.member"
          >
            {{ $empty.empty(scope.row.order.member.mobile) }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" align="center" label="客服备注">
          <template slot-scope="scope" v-if="scope.row.order">
            {{ $empty.empty(scope.row.order.remark) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'tdcafterDetail',
                query: { id: scope.row.id, state: 1, word: 'detail' },
              }"
              class="main-color pointer text-none"
              >查看详情
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from1.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { BASE } from "@/api";
export default {
  name: "supplier",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      url_type: "",
      url_link: {
        general: BASE.PRO1,
        delivery: BASE.PRO3,
        tdc: BASE.PRO4,
      },
      loading: false,
      from: {
        order_no: "",
        mobile: "",
        as_no: "",
        as_at: "",
        page: 1,
        pageSize: 10,
      },
      from1: {
        order_no: "",
        mobile: "",
        as_no: "",
        as_at: "",
        page: 1,
        pageSize: 10,
      },
      value1: [],
      tableData: [],
      currentPage: 1,
      total: 0,
      list: [],
      list1: [],
    };
  },
  created() {
    if (window.location.href.indexOf("?") > -1) {
      /** 历史丢参问题，修复 start */
      const url = this.tool.getUrl(window.location.href);
      // 无效的值
      const invalid = ["", null, undefined, "null", "undefined"];
      url.page = (invalid.includes(url.page) && 1) || url.page;
      url.pageSize = (invalid.includes(url.pageSize) && 10) || url.pageSize;
      /** 历史丢参问题，修复 end */
      this.from = url;
      this.from1 = url;
      this.currentPage = Number(this.from1.page);
      this.from1.pageSize = Number(this.from1.pageSize);
      console.log(this.from1);
    }
    this.hqlist();
  },
  methods: {
    search() {
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    Refresh() {
      this.from = {
        order_no: "",
        mobile: "",
        as_no: "",
        as_at: "",
        page: 1,
        pageSize: 10,
      };
      this.value1 = [];
      this.from1 = {
        order_no: "",
        mobile: "",
        as_no: "",
        as_at: "",
        page: 1,
        pageSize: 10,
      };
      this.currentPage = 1;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    exportHandle() {
      window.open(
        BASE.PRO4 +
          `/aftersale/un/list/export?order_no=` +
          this.from1.order_no +
          "&as_at=" +
          this.from1.as_at +
          "&mobile=" +
          this.from1.mobile +
          "&as_no=" +
          this.from1.as_no +
          "&token=" +
          sessionStorage.getItem("token"),
        "_blank"
      );
      console.log(
        BASE.PRO4 +
          `/aftersale/un/list/export?order_no=` +
          this.from1.order_no +
          "&as_at=" +
          this.from1.as_at +
          "&mobile=" +
          this.from1.mobile +
          "&as_no=" +
          this.from1.as_no +
          "&token=" +
          sessionStorage.getItem("token"),
        "_blank"
      );
    },
    hqlist() {
      this.loading = true;
      this.$api.tdc.aftersaleList(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.supplier {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }
}

.colors {
  color: #fa884c;
}

.times {
  margin-right: 8px;
}

// .el-picker-panel {
//   left: 1245px !important;
// }
</style>
